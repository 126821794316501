import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@provider-reducers/root';

export interface Props {
  track: any;
}

const AudioTrack: React.FC<Props> = ({ track }) => {
  const ref = useRef<(HTMLAudioElement & { setSinkId?: (sinkId: string) => void; sinkId?: string }) | null>(null);

  // When audio output ID changes, change the audio sink on our audio embeds
  const { audioOutputId } = useSelector((state: RootState) => state.settings);

  useEffect(() => {
    if (track && ref.current) {
      const el = ref.current;
      track.attach(el);
      return () => {
        track.detach(el);
      };
    }
  }, [track, ref]);

  useEffect(() => {
    if (ref.current) {
      const el = ref.current;
      el.addEventListener('pause', () => {
        el.play();
      });
    }
  }, [ref]);

  useEffect(() => {
    const updateSinkId = async (newSinkId: string) => {
      try {
        if (ref.current?.sinkId !== newSinkId) {
          await ref.current?.setSinkId?.(newSinkId);
          console.log(`Set sink ID to ${newSinkId}`);
        }
      } catch (error) {
        console.log('Did not update audio sink ID');
      }
    };
    if (audioOutputId && ref.current && ref.current.setSinkId) {
      updateSinkId(audioOutputId);
    }
  }, [audioOutputId]);

  return <audio ref={ref} data-testid='audio' />;
};

export default AudioTrack;
