import React, { useCallback } from 'react';
import {
  setIsAudioControlActive,
  setIsCameraControlActive,
  setSelectedDeviceById,
  setSidePanelCollapsed,
  setSidePanelContent
} from '@provider-reducers/esitterSlice';
import { resetEditMode } from '@provider-reducers/smartRailsSlice';
import { SidePanelContent } from '../../components/SidePanel/SidePanel';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAudioControlHovered, selectIsCameraControlHovered } from '@provider-selectors/app';
import { selectEditMode } from '@provider-selectors/smartRails';
import { selectDeviceSelectedCameraUuid } from '@provider-selectors/esitter';

interface UseSelectRoomBox {
  handleSelectionClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const useSelectRoomBox = (deviceId: string): UseSelectRoomBox => {
  const isCameraControlHovered = useSelector(selectIsCameraControlHovered);
  const isAudioControlHovered = useSelector(selectIsAudioControlHovered);
  const selectedCameraUuid = useSelector(state => selectDeviceSelectedCameraUuid(state, deviceId));
  const editMode = useSelector(state => selectEditMode(state, selectedCameraUuid));

  const dispatch = useDispatch();

  const handleSelectionClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      // Clicking anywhere *except* the camera.audio control should hide it
      if (!isCameraControlHovered) {
        dispatch(setIsCameraControlActive(false));
      }

      if (!isAudioControlHovered) {
        dispatch(setIsAudioControlActive(false));
      }

      if (!editMode) {
        dispatch(resetEditMode());
        dispatch(setSelectedDeviceById(deviceId));
        dispatch(setSidePanelContent(SidePanelContent.PATIENT_DETAILS));
        dispatch(setSidePanelCollapsed(false));
      }
    },
    [deviceId, dispatch, editMode, isAudioControlHovered, isCameraControlHovered]
  );
  return {
    handleSelectionClick
  };
};
